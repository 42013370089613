






























































import { mapGetters, mapState } from 'vuex';
import { roundNumber } from '@/helpers/roundNumber';
import { tokensToCurrency } from '@/helpers/currencyHelper';
import DashboardTokenStatisticTile from '@/views/DashboardViews/dashboard-view/DashboardTokenStatisticTile.vue';
import type { PropType } from 'vue';

export default {
  name: 'DashboardTokenStatistic',
  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  components: {
    DashboardTokenStatisticTile
  },
  computed: {
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('Onboarding', ['loading']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('Dashboard', [
      'accountBalance',
      'accountBalanceLastWeekRatio',
      'walletBalances',
      'walletBalancesLastWeekRatio',
      'inStake',
      'inStakeLastWeekRatio'
    ]),
    ...mapState('StakingPrograms', [
      'totalInStakingUsers',
      'totalUsersLastWeekRatio'
    ]),
    ...mapGetters('ClientsInfo', ['isListed']),
    priceProgress(): number {
      return this.isListed ? this.tokenPrice.weeklyRatio : 0;
    },
    tokenPriceSubTitle(): string {
      return `${
        this.tokenPrice.rateCurrencySymbol
      } ${this.$options.filters.numeralSpaces(
        !this.loading.formData ? this.tokenPrice.price : 0,
        `0,0.[00000]`
      )}`;
    },
    accountBalanceTitle(): string {
      return `${this.$options.filters.numeralSpaces(
        !this.isLoading ? this.accountBalance : 0,
        `0,0`
      )} ${this.tokenSymbol}
      `;
    },
    accountBalanceSubTitle(): string {
      return `${this.$options.filters.numeralSpaces(
        !this.isLoading ? tokensToCurrency(this.accountBalance) : 0,
        `0,0`
      )} ${this.tokenPrice.rateCurrencyTicker}
      `;
    },
    fundsH2ETitle(): string {
      return `${this.$options.filters.numeralSpaces(
        !this.isLoading ? this.inStake : 0,
        `0,0`
      )} ${this.tokenSymbol}
      `;
    },
    fundsH2ESubTitle(): string {
      return `${this.$options.filters.numeralSpaces(
        !this.isLoading ? tokensToCurrency(this.inStake) : 0,
        `0,0`
      )} ${this.tokenPrice.rateCurrencyTicker}
      `;
    },
    walletBalanceTitle(): string {
      return `${this.$options.filters.numeralSpaces(
        !this.isLoading ? this.walletBalances : 0,
        `0,0`
      )} ${this.tokenSymbol}
      `;
    },
    walletBalanceSubTitle(): string {
      return `${this.$options.filters.numeralSpaces(
        !this.isLoading ? tokensToCurrency(this.walletBalances) : 0,
        `0,0`
      )} ${this.tokenPrice.rateCurrencyTicker}
      `;
    }
  },
  methods: {
    roundNumber
  }
};
