



































import type { PropType } from 'vue';

export default {
  name: 'DashboardTokenStatisticTile',
  props: {
    color: {
      type: String as PropType<string>,
      default: (): string => '#3A3541'
    },
    icon: {
      type: String as PropType<string>,
      default: (): string => 'mdi-alert-circle-outline'
    },
    header: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    title: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    subTitle: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    weekProgress: {
      type: Number as PropType<number>,
      default: (): number => 0
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    hideProgressIcon: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  computed: {
    changeType(): boolean {
      return this.weekProgress >= 0;
    },
    notZero(): boolean {
      return !!this.weekProgress;
    },
    classes(): any {
      return {
        'success--text': this.weekProgress > 0,
        'error--text': this.weekProgress < 0
      };
    },
    hidePercents(): boolean {
      return this.weekProgress >= 20;
    }
  }
};
